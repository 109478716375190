import type React from "react";
import { Redirect, useLocation } from "wouter";
import { useAuth } from "../hooks/useAuth";
import { Paths } from "../paths";

interface Props {
  children: React.ReactNode;
}

export const RequireAuth = ({ children }: Props) => {
  const [location] = useLocation();
  const {
    isLoggedIn,
    idaas: { isLoading },
  } = useAuth();

  if (isLoading) {
    return <></>;
  }

  return isLoggedIn() ? children : <Redirect to={Paths.login} replace state={{ path: location }} />;
};
